<template>
<div class="card categories" style="width: 18rem;">
  <div class="card-header"><i class="fas fa-list"></i> Kategorien</div>
  <ul v-if="categories" class="list-group list-group-flush">
    <li v-for="(item, index) in categories" :key="index" class="list-group-item" @click.prevent="changeCategory(item.code)">
      {{ item.name }}
    </li>
  </ul>
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const store = useStore()
    const router = useRouter()
    const categories = computed(() => {
      return store.getters['product/categories']
    })
    function changeCategory (slug) {
      router.push({ name: 'category', params: { name: slug } })
    }
    return {
      categories,
      changeCategory
    }
  }
}
</script>
<style lang="scss" scoped>
.categories {
  .list-group-item {
    cursor: pointer;
  }
  .list-group-item:hover {
    background-color: #0d6efd;
    color: #fff;
  }
}
</style>
