<template>
  <main v-if="status" class="content container">
    <h1>{{ category.name }}</h1>
    <p>{{ category.description }}</p>
    <div class="row">
      <div class="col-sm-3">
        <Categories />
      </div>
      <div class="col-sm-9">
        <div class="row">
          <Product v-for="(item, index) in items.data" :key="index" :product="item" />
        </div>
        <hr>
        <Pagination :pagination="items" :offset="offset" @paginate="getData" />
      </div>
    </div>
  </main>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onMounted, watch } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { useMeta } from 'vue-meta'
import axios from 'axios'
import Categories from '~/pages/products/categories'
import Pagination from '~/components/Pagination'
import Product from '~/pages/products/product'
import Slugify from 'underscore.string/slugify'
export default {
  components: {
    Categories,
    Pagination,
    Product
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const items = ref({
      total: 0,
      perPage: 2,
      from: 1,
      to: 0,
      current_page: 1
    })
    const offset = ref(4)
    const title = ref('Kategorien')
    const status = ref(false)
    const category = computed(() => {
      return store.getters['product/category']
    })
    // methods
    async function getData () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      const name = Slugify(route.params.name)
      const params = { page: items.value.current_page, re: apiKey }
      await axios.get(apiUrl + 'singlecat/' + name, { params }).then((resp) => {
        store.dispatch('product/setCategory', { category: resp.data.category })
        store.dispatch('product/setProducts', { products: resp.data.products.data })
        title.value = (category.value.name ? category.value.name : 'Not found')
        items.value = resp.data.products
        status.value = true
      })
    }
    async function getCategories () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      const params = { re: apiKey }
      await axios.get(apiUrl + 'singlecategories', { params }).then((resp) => {
        store.dispatch('product/setCategories', { categories: resp.data })
      })
    }
    onMounted(() => {
      getData()
      getCategories()
    })
    watch(() => route.path, () => {
      items.value.current_page = 1
      getData()
    })
    useMeta({
      title: title.value
    })
    return {
      status,
      items,
      offset,
      category,
      getData
    }
  }
}
</script>
