<template>
<nav v-if="pagination" aria-label="Page navigation example">
  <ul class="pagination">
    <li v-if="pagination.current_page > 1" class="page-item">
      <a class="page-link" href="javascript:void(0)" aria-label="Previous" @click.prevent="changePage(pagination.current_page - 1)">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li v-for="page in pagesNumber" :key="page.current_page" :class="{'active': page == pagination.current_page}" class="page-item">
      <a class="page-link" href="javascript:void(0)" @click.prevent="changePage(page)">{{ page }}</a>
    </li>
    <li v-if="pagination.current_page < pagination.last_page" class="page-item">
      <a class="page-link" href="javascript:void(0)" aria-label="Next" @click.prevent="changePage(pagination.current_page + 1)">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>
</template>
<script>
import { computed, toRefs } from '@vue/reactivity'
export default {
  props: {
    pagination: {
      type: Object,
      required: true
    },
    offset: {
      type: Number,
      default: 4
    }
  },
  emits: ['paginate'],
  setup (props, { emit }) {
    const { pagination } = toRefs(props)
    const pagesNumber = computed(() => {
      if (!pagination.value.to) {
        return []
      }
      let from = pagination.value.current_page - props.offset
      if (from < 1) {
        from = 1
      }
      let to = from + (props.offset * 2)
      if (to >= pagination.value.last_page) {
        to = pagination.value.last_page
      }
      const pagesArr = []
      for (let page = from; page <= to; page++) {
        pagesArr.push(page)
      }
      return pagesArr
    })
    function changePage (page) {
      pagination.value.current_page = page
      emit('paginate')
    }
    return {
      pagesNumber,
      changePage
    }
  }
}
</script>
